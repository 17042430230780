<template>
  <div>
    <b-overlay :show="loading" :opacity="0" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row v-if="!users || users.length < 1">
        <discover-no-user>
          <h3 class="color-black">
            {{ $t('You don\'t have any favorite users yet') }}
            <router-link :to="{name: 'discover'}" style="text-decoration: underline">
              {{ $t('Start discover') }}
            </router-link>
          </h3>
        </discover-no-user>
      </b-row>
      <b-row>
        <user-card v-for="user in users" :key="user.id" :user="user" @setLoading="setLoading($event)"/>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { BCol, BOverlay, BRow } from 'bootstrap-vue'
import UserCard from '@/components/UserCard'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import useJwt from "@/auth/jwt/useJwt";
import {throwDefaultError} from "@/helpers/helpers";

export default {
  name: 'interaction-favorite-users',
  props: {
    activeTabIndex: {
      type: Number,
      default: 1
    }
  },
  components: {
    BCol,
    BRow,
    BOverlay,

    DiscoverNoUser,
    UserCard,
  },
  data() {
    return {
      loading: false,
      scrollOffsetForLoadMore: 100,
      users: [],
      usersLimit: 100,
      usersOffset: 0,
      usersAllLoaded: false,
    }
  },
  created() {
    this.getUsers()

    // Scroll'u dinle
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    getUsers (clear = true) {
      this.loading = true
      useJwt.favoriteUsers(this.usersOffset, this.usersLimit)
          .then(response => {
            try {
              if (clear) {
                this.users = response.data.body.users.map(u => { u['fav'] = true; return u })
              } else {
                this.users.push(...response.data.body.users.map(u => { u['fav'] = true; return u }))
              }

              // Offset ayarla
              this.usersOffset = response.data.body.offset + response.data.body.limit

              if (this.usersOffset >= response.data.body.totalCount) {
                this.usersAllLoaded = true
              }
            } catch (e) {

            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    handleScroll (event) {
      if (this.activeTabIndex === 1) {
        if ((window.innerHeight + window.scrollY + this.scrollOffsetForLoadMore) >= document.body.scrollHeight &&
            !this.usersAllLoaded) {
          this.loadMore()
        }
      }
    },
    loadMore() {
      if (!this.loading) {
        this.getUsers(false)
      }
    },
    setLoading($event) {
      this.loading = $event
    },
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #F9447A;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
